const UsageCounters = {
  times_played_notes:       0,
  times_played_transport:   0,
  scales_viewd:             0,
  chords_viewd:             0,
  progressions_viewd:       0,
  times_changed_instrument: 0,
  scores_created:           0,
  clicked_help:             0,
  opened_drawer:            0,
  opened_options_drawer:    0,
  changed_music_line_speed: 0,
  changed_music_line:       0,
  changed_play_pattern:     0,
  changed_pitch_class:      0,
  changed_octave:           0,
  saved_data:               0,
  opened_settings:          0,
}

export default UsageCounters
