<template>
  <v-row class="text-center white--text pt-0">
    <v-col :cols="12" v-if="$vuetify.breakpoint.xsOnly"
           class="py-0">
      <h1 class="headline font-weight-black">
        {{ appName }}
        <span class="overline">
          By
        </span>
        <br>
        <v-img contain alt="Logo" max-width="120"
               :src="require(`framework/resources/img/full-logo-white.png`)"
               class="py-2"
               style="display: inline-block;cursor: pointer"
               @click="openMusitelligence"/>
      </h1>
    </v-col>
    <v-col :cols="12" v-else class="py-0">
      <h1 class="display-1 font-weight-black">
        {{ appName }}
        <span class="body-2">
          By
        </span>
        <br>
        <v-img contain alt="Logo" max-width="150"
               :src="require(`framework/resources/img/full-logo-white.png`)"
               class="py-2"
               style="display: inline-block;cursor: pointer"
               @click="openMusitelligence"/>
      </h1>
    </v-col>
    <v-col :cols="12"
           v-if="!appInstanceConfig.isStandAlone && !appInstanceConfig.isTWA"
           @click="viewInstallInstructions"
           :class="`font-weight-regular ${$vuetify.breakpoint.mdAndUp ? 'body-1' :'body-2'}`"
           style="cursor: pointer;">
      <span style="border-bottom: solid 1px">
        Install everywhere
        <app-icon icon="info" :size="14"/>
      </span>
    </v-col>
    <v-col :cols="12"
           v-for="page in appPages"
           :key="page.name">
      <router-link :to="page.path">
        <v-btn rounded
               :large="$vuetify.breakpoint.xsOnly"
               :x-large="$vuetify.breakpoint.smAndUp"
               :style="{width: $vuetify.breakpoint.smAndUp ? '50vw' : '85vw'}"
               :class="`elevation-2 white--text text-capitalize ${$vuetify.breakpoint.mdAndUp ? 'subheading' :
                 'subheading'}`"
               style="background: rgba(255,255,255,0.2)">
          {{ page.name }}
        </v-btn>
      </router-link>
    </v-col>
    <v-col :cols="12" v-if="shouldShowIOSWarning" class="black">
      Make sure the mute switch is off or you wont hear anything.
      <v-btn icon @click="isIOSWarningDismissed=true">
        <app-icon icon="close" size="18"/>
      </v-btn>
    </v-col>
    <v-col cols="12" v-if="newContentAvailable">
      <v-btn x-large color="success darken-2" @click="serviceWorkerSkipWaiting">
        <span :class="`${$vuetify.breakpoint.width < 340 ? 'caption' : 'body-2'} text-none`">
          New version available,
          CLICK HERE To update<br>
          (A simple refresh)
        </span>
      </v-btn>
    </v-col>
    <v-col :cols="12" class="font-weight-medium">
      <v-btn text>
        <a href="https://www.patreon.com/Seanitzel" target="_blank" rel="noreferrer noopener"
           class="text-none accent--text body-1">
          Please support us for more
          <app-icon icon="double-arrow" size="12"/>
        </a>
      </v-btn>
      <br>
      <a :href="sm.link" target="_blank" rel="noreferrer noopener" v-for="sm in socialMedia"
         :key="sm.name">
        <v-btn large icon @click="socialMediaClicked(sm)">
          <app-icon size="34" :icon="sm.name" color="white"/>
        </v-btn>
      </a>
    </v-col>
    <v-dialog v-model="installInstructionsDialog" max-width="350">
      <app-dialog @close="installInstructionsDialog = false" :padding="0">
        <template #title>Installation instructions</template>
        <install-instructions/>
      </app-dialog>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SegmentIO                  from 'reporting/SegmentIO'
import appConfig                  from 'app-config/app.json'
import appInstanceConfig          from 'framework/environment/platform'

export default {
  name: 'Home',

  head: {
    title: function() {
      return {
        inner: 'Home'
      }
    },
    meta:  appConfig.meta
  },

  components: {
    InstallInstructions: () => import(/* webpackChunkName: "home" */ 'framework/ui/sections/InstallInstructions'),
    AppDialog:           () => import(/* webpackChunkName: "app-main" */ 'framework/ui/components/AppDialog')
  },

  data() {
    return {
      appInstanceConfig,
      appName:                   appConfig.name,
      installInstructionsDialog: false,
      appPages:                  appConfig.appRoutes,
      isIOSWarningDismissed:     false,
      socialMedia:               [
        {
          name: 'twitter',
          link: 'https://twitter.com/musitelligence'
        },
        {
          name: 'facebook',
          link: 'https://www.facebook.com/musitelligence'
        }
      ]
    }
  },

  computed: {
    ...mapGetters('environment', ['newContentAvailable']),
    shouldShowIOSWarning: function() {
      return appInstanceConfig.isIOS && !this.isIOSWarningDismissed
    }
  },

  methods: {
    ...mapActions('environment', ['serviceWorkerSkipWaiting']),
    viewInstallInstructions() {
      SegmentIO.viewInstallInstructions()
      this.installInstructionsDialog = true
    },

    socialMediaClicked({ name }) {
      SegmentIO.socialMediaClicked(name)
    },

    openMusitelligence() {
      window.open('https://musitelligence.com/', '_blank')
    }
  }
}
</script>
