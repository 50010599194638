import UsageCounters from 'reporting/UsageCounters'
import firebase      from 'firebase/app'
import 'firebase/analytics'
import { version }   from '../../package.json'

const SegmentIO = {
  init() {
    const { navigator, document, screen } = window
    const event_timestamp_on_device       = new Date()
    const trackingApplicationName         = 'vocal-coach'

    const mandatoryFields = {
      tracking_application_name:    trackingApplicationName,
      tracking_application_version: version,
      browser_height:               window.innerHeight,
      browser_width:                window.innerWidth,
      browser_cookies_enabled:      navigator.cookieEnabled,
      browser_encoding:             document.characterSet,
      screen_color_depth:           screen.colorDepth,
      platform:                     navigator.platform,
      event_timestamp_on_device,
    }

    const commonData         = {
      is_user_signed_in: false,
    }
    SegmentIO.commonData     = { ...commonData, ...mandatoryFields }
    SegmentIO.additionalData = {}
  },

  setIsUserLoggedIn(isUserLoggedIn) {
    SegmentIO.commonData['is_user_logged_in'] = isUserLoggedIn
  },

  getCommonData() {
    return SegmentIO.commonData
  },

  initialViewEvent() {
    const commonData = SegmentIO.getCommonData()

    const additionalData = {
      event_action: 'viewed',
      event_name:   'viewed:home',
      screen_path:  'scale_heaven/home',
      screen:       'home',
      status:       'success',
    }
    const message        = {
      event:      'loaded_scale_heaven',
      properties: { ...commonData, ...additionalData },
    }
    SegmentIO.track(message)
  },

  clickLogin() {
    const commonData     = SegmentIO.getCommonData()
    const additionalData = {
      event_name:       'click_login',
      event_action:     'started',
      event_object:     'login_button',
      ui_action:        'clicked',
      ui_object_detail: 'login',
    }

    const message = {
      event:      'click_login',
      properties: { ...commonData, ...additionalData },
    }
    SegmentIO.track(message)
  },

  clickTermsOfUse() {
    const commonData     = SegmentIO.getCommonData()
    const additionalData = {
      event_name:          'click_terms_of_service',
      event_action:        'open',
      event_object:        'drawer',
      event_object_detail: 'terms_of_service',
      ui_action:           'click',
      ui_object:           'list_element',
      ui_object_detail:    'terms_of_service',
    }

    const message = {
      event:      'click_terms_of_service',
      properties: { ...commonData, ...additionalData },
    }
    SegmentIO.track(message)
  },

  clickPrivacy() {
    const commonData     = SegmentIO.getCommonData()
    const additionalData = {
      event_name:          'click_privacy_link',
      event_action:        'open',
      event_object:        'drawer',
      event_object_detail: 'privacy',
      ui_action:           'click',
      ui_object:           'list_element',
      ui_object_detail:    'privacy',
    }

    const message = {
      event:      'click_privacy_link',
      properties: { ...commonData, ...additionalData },
    }
    SegmentIO.track(message)
  },

  aboutMeClicked() {
    const commonData     = SegmentIO.getCommonData()
    const additionalData = {
      event_name:          'open:about_me',
      event_action:        'open',
      event_object:        'about_me',
      event_object_detail: 'about_me',
      ui_action:           'click',
      ui_object:           'dialog',
      ui_object_detail:    'about_me',
    }

    const message = {
      event:      'click_about_me',
      properties: { ...commonData, ...additionalData },
    }
    SegmentIO.track(message)
  },

  viewNewsClicked() {
    const commonData     = SegmentIO.getCommonData()
    const additionalData = {
      event_name:          'open:click_news_and_changelog',
      event_action:        'open',
      event_object:        'news_and_changelog',
      event_object_detail: 'news_and_changelog',
      ui_action:           'click',
      ui_object:           'dialog',
      ui_object_detail:    'news_and_changelog',
    }

    const message = {
      event:      'click_news_and_changelog',
      properties: { ...commonData, ...additionalData },
    }
    SegmentIO.track(message)
  },

  socialMediaClicked(name) {
    const commonData     = SegmentIO.getCommonData()
    const additionalData = {
      event_name:          'click_social_media',
      event_action:        'open',
      event_object:        'social_media_icon',
      event_object_detail: 'social_media_icon',
      ui_action:           'click',
      ui_object:           'link',
      ui_object_detail:    'icon',
      social_media_name:   name,
    }

    const message = {
      event:      'click_social_media',
      properties: { ...commonData, ...additionalData },
    }
    SegmentIO.track(message)
  },

  onLeave() {
    const commonData     = SegmentIO.getCommonData()
    const additionalData = {
      event_name: 'quit_app',
      ui_action:  'quit',
      ...UsageCounters,
    }

    const message = {
      event:      'quit_app',
      properties: { ...commonData, ...additionalData },
    }
    SegmentIO.track(message)
  },

  viewInstallInstructions() {
    const commonData     = SegmentIO.getCommonData()
    const additionalData = {
      event_name:          'click_install_instrctions',
      event_action:        'open',
      event_object:        'app_install_instructions_tip',
      event_object_detail: 'app_install_tip_home_page',
      ui_action:           'click',
      ui_object:           'link',
      ui_object_detail:    'install_instructions',
    }

    const message = {
      event:      'click_install_instrctions',
      properties: { ...commonData, ...additionalData },
    }
    SegmentIO.track(message)
  },

  /**
   * Midi download event
   * @param downloadedData The numbers of each type of data downloaded. e.g {scales: 4}
   */
  midiDownload(downloadedData) {
    const commonData     = SegmentIO.getCommonData()
    const additionalData = {
      event_name:   'midi_download',
      event_action: 'download',
      event_object: 'midi_download_menu',
      ui_action:    'click',
      ui_object:    'button',
      ...downloadedData,
    }

    const message = {
      event:      'midi_download',
      properties: { ...commonData, ...additionalData },
    }
    SegmentIO.track(message)
  },

  /**
   * Facade for Segment.IO Track
   * @param {{event: String, properties: Object}} param
   */
  track({ event, properties }) {
    if(process.env.NODE_ENV === 'production') {
      window.analytics && window.analytics.track(
        event,
        properties,
        { context: { tracking_application_name: properties.tracking_application_name } },
      )
      firebase.analytics().logEvent(event, properties)
    }
  },
}

export default SegmentIO
